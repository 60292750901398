import { IGeneralLead } from "@/models/Conditions";

export default {
  //RNT
  isRNTQualified: (RNTLead: IGeneralLead) => {
    if (!RNTLead.isNewOrRevived) return false;
    if (!RNTLead.isTargetZone) return false;
    if (RNTLead.isTestEmail) return false;
    return true;
  },
};
