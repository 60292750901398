
import { Component, Prop, Vue } from "vue-property-decorator";
import {
  HousfyFlap,
  HousfyInputAddress,
  HousfyButton,
  HousfyInputPhone,
} from "housfy-ui-lib";
import MyRentalsConfig from "@/services/MyRentalsConfig";
import AuthService from "@/services/AuthService";
import MarketingService from "@/services/MarketingService";
import Conditions from "@/services/marketing-services/Trackings/Conditions";
import { IGoogleAddress } from "@/models/IGoogleAddress";
import { IRNTQualifiedOptions } from "@/models/IRNTQualifiedOptions";
import { IRNTLeadPayload } from "@/models/IRNTLeadPayload";
import api from "@/api";
import { TranslateResult } from "vue-i18n";
import ErrorService from "@/services/ErrorService";
import { IHousfyError } from "@/models/HousfyError";
import HousfySvg from "@/components/ui/HousfySvg.vue";
import { IPhoneData } from "@/models/Phone";
import { HousfyAlertMessage } from "housfy-ui-lib";

@Component({
  components: {
    HousfyFlap,
    HousfyInputAddress,
    HousfyButton,
    HousfyInputPhone,
    HousfySvg,
    HousfyAlertMessage,
  },
})
export default class MoreInformationFlapForm extends Vue {
  @Prop({ type: String, required: true }) id!: string;
  @Prop({ type: Number, required: true }) originId!: number;
  @Prop({ type: String, required: true }) role!: string;

  address: IGoogleAddress | null = null;
  loading = false;
  inputAddressValue = "";

  phoneData: IPhoneData | null = null;

  error = false;
  buttonPressed = false;

  get googleApiKey(): string {
    return MyRentalsConfig.env().GOOGLE_MAPS_API_KEY_SECRET;
  }

  get headerFlap(): TranslateResult {
    return this.requirePhone
      ? this.$t("common.getFreeInformation")
      : this.$t("common.whereIsYourHome");
  }

  get descriptionFlap(): TranslateResult {
    return this.requirePhone
      ? this.$t("common.leaveUsYourData")
      : this.$t("common.indicateTheCityOfTheHouse");
  }

  get cta(): TranslateResult {
    return this.requirePhone
      ? this.$t("common.requestInformation")
      : this.$t("common.confirm");
  }

  get leadPhone(): string | undefined {
    return this.requirePhone
      ? this.phoneData?.internationalPhone.replaceAll(" ", "")
      : AuthService.currentUser.phone?.internationalPhone;
  }

  get phoneStatus(): string {
    if (!this.requirePhone) return "";
    return !this.buttonPressed || this.phoneData?.isValid ? "" : "error";
  }

  get phoneSupportiveText(): string | null {
    if (this.phoneStatus !== "error") return null;
    return "common.oopsWritePhone";
  }

  get addressStatus(): string {
    return !this.buttonPressed || this.addressIsValid ? "" : "error";
  }

  get addressSupportiveText(): string | null {
    if (this.addressStatus !== "error") return null;
    return "common.oopsAddTheLocation";
  }

  get addressIsValid(): boolean {
    return !!this.address?.geometry;
  }

  get requirePhone(): boolean {
    return !AuthService.currentUser.phone?.internationalPhone;
  }

  setAddress(data: IGoogleAddress) {
    this.address = data;
  }

  setPhone(data: IPhoneData) {
    this.phoneData = data;
  }

  async generateLead() {
    this.loading = true;
    this.buttonPressed = true;

    if (!this.address?.geometry) {
      this.loading = false;
      return;
    }

    if (
      this.requirePhone &&
      (!this.phoneData?.internationalPhone || !this.phoneData.isValid)
    ) {
      this.loading = false;
      return;
    }
    const data: IRNTLeadPayload = {
      latitude: this.address?.geometry.location.lat(),
      longitude: this.address?.geometry.location.lng(),
      countryIsoCode: MyRentalsConfig.country()?.toUpperCase(),
      email: AuthService.currentUser.email || "",
      internationalPhone: this.leadPhone || "",
      isBot: false,
      originId: this.originId,
      referer: document.referrer || window.location.href,
      role: this.role,
    };

    try {
      this.error = false;

      if (this.requirePhone && this.leadPhone)
        await api.auth().updateProfile({ phone: this.leadPhone });

      const createdLead = await api.lead().rentals(data);

      const isRNTQualifiedOptions: IRNTQualifiedOptions = {
        isNewOrRevived:
          createdLead.isLead30DaysOldOrMore || createdLead.isLeadNew,
        isTargetZone: createdLead.isTargetZone,
        isTestEmail: createdLead.isTestEmail,
      };

      MarketingService.analytics().trackRNTLeadOwnerGoal2();

      if (Conditions.isRNTQualified(isRNTQualifiedOptions))
        MarketingService.analytics().trackRNTLeadViableGoal4();

      this.$emit("success");
    } catch (err) {
      this.error = true;
      ErrorService.handleLeadGenerationError(err as IHousfyError, data);
    } finally {
      this.loading = false;
    }
  }
}
