import {
  testMyRentalsShallowMount,
  myRentalsShallowMount,
} from "@/tests/unit/setup";
import MoreInformationFlapForm from "@/components/forms/MoreInformationFlapForm.vue";
import AuthService from "@/services/AuthService";
import api from "@/api";
import MarketingService from "@/services/MarketingService";

/* MOCKS */
jest.mock("@/api");
jest.mock("@/services/MyRentalsConfig");

const defaultProps = {
  id: "1",
  originId: 1,
  role: "role",
};
const component = (data: any = {}, props: any = {}) => {
  return myRentalsShallowMount(MoreInformationFlapForm, {
    data,
    props: { ...defaultProps, ...props },
  });
};

describe("MoreInformationFlapForm", () => {
  afterEach(() => {
    jest.restoreAllMocks();
  });
  beforeEach(() => {
    jest.spyOn(AuthService, "currentUser", "get").mockReturnValue({});
    Object.defineProperty(window, "location", {
      value: { href: "href" },
    });
  });
  testMyRentalsShallowMount(MoreInformationFlapForm);

  describe("Phone Input", () => {
    it("It should show the expanded form if the user hasn't indicated their phone", () => {
      //given
      jest.spyOn(AuthService, "currentUser", "get").mockReturnValue({});

      //when
      const flapForm = component();

      //then
      expect(flapForm.vm.requirePhone).toBeTruthy();
      expect(flapForm.vm.headerFlap).toEqual("common.getFreeInformation");
      expect(flapForm.vm.descriptionFlap).toEqual("common.leaveUsYourData");
    });

    it("It should show the expanded form if the user has indicated their phone", () => {
      //given
      jest.spyOn(AuthService, "currentUser", "get").mockReturnValue({
        phone: {
          countryCode: 34,
          phone: "phone",
          internationalPhone: "international phone",
        },
      });

      //when
      const flapForm = component();

      //then
      expect(flapForm.vm.requirePhone).toBeFalsy();
      expect(flapForm.vm.headerFlap).toEqual("common.whereIsYourHome");
      expect(flapForm.vm.descriptionFlap).toEqual(
        "common.indicateTheCityOfTheHouse"
      );
    });
  });

  describe("Generate lead", () => {
    it("Should not generate lead if address is not correctly set", () => {
      //given
      jest.spyOn(AuthService, "currentUser", "get").mockReturnValue({
        phone: {
          countryCode: 34,
          phone: "phone",
          internationalPhone: "international phone",
        },
      });
      spyOn(api.lead(), "rentals");

      //when
      const flapForm = component();
      flapForm.vm.generateLead();

      expect(api.lead().rentals).not.toHaveBeenCalled();
      expect(flapForm.vm.addressStatus).toContain("error");
      expect(flapForm.vm.loading).toBeFalsy();
      expect(flapForm.vm.error).toBeFalsy();
      expect(flapForm.vm.buttonPressed).toBeTruthy();
      expect(flapForm.vm.phoneStatus).not.toContain("error");
    });

    it("Should not generate lead if phone is not correctly set", () => {
      //given
      jest.spyOn(AuthService, "currentUser", "get").mockReturnValue({});
      spyOn(api.lead(), "rentals");

      //when
      const flapForm = component();
      flapForm.vm.setAddress({ geometry: "geometry" });
      flapForm.vm.generateLead();

      expect(api.lead().rentals).not.toHaveBeenCalled();
      expect(flapForm.vm.addressStatus).not.toContain("error");
      expect(flapForm.vm.phoneStatus).toContain("error");
      expect(flapForm.vm.buttonPressed).toBeTruthy();
      expect(flapForm.vm.error).toBeFalsy();
    });

    it("Should not generate lead if the phone is invalid", () => {
      //given
      jest.spyOn(AuthService, "currentUser", "get").mockReturnValue({});
      spyOn(api.lead(), "rentals");

      //when
      const flapForm = component();
      flapForm.vm.setAddress({ geometry: "geometry" });
      flapForm.vm.setPhone({ internationalPhone: "phone", isValid: false });
      flapForm.vm.generateLead();

      expect(api.lead().rentals).not.toHaveBeenCalled();
      expect(flapForm.vm.addressStatus).not.toContain("error");
      expect(flapForm.vm.phoneStatus).toContain("error");
      expect(flapForm.vm.buttonPressed).toBeTruthy();
      expect(flapForm.vm.error).toBeFalsy();
    });

    it("Should generate the lead if using the expanded form and everything is correctly set, and it should call marketing services if it is a new lead", async () => {
      //given
      jest.spyOn(AuthService, "currentUser", "get").mockReturnValue({});
      spyOn(api.lead(), "rentals").and.returnValue({
        isNewOrRevived: false,
        isLeadNew: true,
        isTargetZone: true,
        isTestEmail: false,
      });
      spyOn(api.auth(), "updateProfile");
      spyOn(MarketingService.analytics(), "trackRNTLeadOwnerGoal2");
      spyOn(MarketingService.analytics(), "trackRNTLeadViableGoal4");

      //when
      const flapForm = component();
      flapForm.vm.setAddress({
        geometry: {
          location: {
            lat: jest.fn(() => 1),
            lng: jest.fn(() => 2),
          },
        },
      });
      const phone = { internationalPhone: "+34phone", isValid: true };
      flapForm.vm.setPhone(phone);

      await flapForm.vm.generateLead();

      //then
      expect(api.auth().updateProfile).toHaveBeenCalledWith({
        phone: "+34phone",
      });
      expect(api.lead().rentals).toHaveBeenCalledWith({
        countryIsoCode: "ES",
        email: "",
        internationalPhone: "+34phone",
        isBot: false,
        latitude: 1,
        longitude: 2,
        originId: 1,
        referer: "href",
        role: "role",
      });
      expect(flapForm.vm.phoneStatus).not.toContain("error");
      expect(flapForm.vm.buttonPressed).toBeTruthy();
      expect(flapForm.vm.error).toBeFalsy();
      expect(
        MarketingService.analytics().trackRNTLeadOwnerGoal2
      ).toHaveBeenCalled();
      expect(
        MarketingService.analytics().trackRNTLeadViableGoal4
      ).toHaveBeenCalled();

      expect(flapForm.emitted().success?.length).toBe(1);
    });

    it("Should generate the lead if using the expanded form and everything is correctly set, and it should call marketing services because it is an old lead", async () => {
      //given
      jest.spyOn(AuthService, "currentUser", "get").mockReturnValue({});
      spyOn(api.lead(), "rentals").and.returnValue({
        isLead30DaysOldOrMore: true,
        isLeadNew: false,
        isTargetZone: true,
        isTestEmail: false,
      });
      spyOn(api.auth(), "updateProfile");
      spyOn(MarketingService.analytics(), "trackRNTLeadOwnerGoal2");
      spyOn(MarketingService.analytics(), "trackRNTLeadViableGoal4");

      //when
      const flapForm = component();
      flapForm.vm.setAddress({
        geometry: {
          location: {
            lat: jest.fn(() => 1),
            lng: jest.fn(() => 2),
          },
        },
      });
      const phone = { internationalPhone: "+34phone", isValid: true };
      flapForm.vm.setPhone(phone);

      await flapForm.vm.generateLead();

      //then
      expect(api.auth().updateProfile).toHaveBeenCalledWith({
        phone: "+34phone",
      });
      expect(api.lead().rentals).toHaveBeenCalledWith({
        countryIsoCode: "ES",
        email: "",
        internationalPhone: "+34phone",
        isBot: false,
        latitude: 1,
        longitude: 2,
        originId: 1,
        referer: "href",
        role: "role",
      });
      expect(flapForm.vm.phoneStatus).not.toContain("error");
      expect(flapForm.vm.buttonPressed).toBeTruthy();
      expect(flapForm.vm.error).toBeFalsy();
      expect(
        MarketingService.analytics().trackRNTLeadOwnerGoal2
      ).toHaveBeenCalled();
      expect(
        MarketingService.analytics().trackRNTLeadViableGoal4
      ).toHaveBeenCalled();

      expect(flapForm.emitted().success?.length).toBe(1);
    });

    it("Should generate the lead if using the expanded form and everything is correctly set, and it should not call some marketing servicesbecause its not a new lead nor older than 30 days", async () => {
      //given
      jest.spyOn(AuthService, "currentUser", "get").mockReturnValue({});
      spyOn(api.lead(), "rentals").and.returnValue({
        isLead30DaysOldOrMore: false,
        isLeadNew: false,
        isTargetZone: false,
        isTestEmail: false,
      });
      spyOn(api.auth(), "updateProfile");
      spyOn(MarketingService.analytics(), "trackRNTLeadOwnerGoal2");
      spyOn(MarketingService.analytics(), "trackRNTLeadViableGoal4");

      //when
      const flapForm = component();
      flapForm.vm.setAddress({
        geometry: {
          location: {
            lat: jest.fn(() => 1),
            lng: jest.fn(() => 2),
          },
        },
      });
      const phone = { internationalPhone: "+34phone", isValid: true };
      flapForm.vm.setPhone(phone);

      await flapForm.vm.generateLead();

      //then
      expect(api.auth().updateProfile).toHaveBeenCalledWith({
        phone: "+34phone",
      });
      expect(api.lead().rentals).toHaveBeenCalledWith({
        countryIsoCode: "ES",
        email: "",
        internationalPhone: "+34phone",
        isBot: false,
        latitude: 1,
        longitude: 2,
        originId: 1,
        referer: "href",
        role: "role",
      });
      expect(flapForm.vm.phoneStatus).not.toContain("error");
      expect(flapForm.vm.buttonPressed).toBeTruthy();
      expect(flapForm.vm.error).toBeFalsy();
      expect(
        MarketingService.analytics().trackRNTLeadOwnerGoal2
      ).toHaveBeenCalled();
      expect(
        MarketingService.analytics().trackRNTLeadViableGoal4
      ).not.toHaveBeenCalled();

      expect(flapForm.emitted().success?.length).toBe(1);
    });

    it("Should generate the lead if using the normal form and everything is correctly set, and it should call marketing services if it is a new lead", async () => {
      //given
      jest.spyOn(AuthService, "currentUser", "get").mockReturnValue({
        phone: {
          countryCode: 34,
          phone: "phone",
          internationalPhone: "international phone",
        },
      });
      spyOn(api.lead(), "rentals").and.returnValue({
        isLead30DaysOldOrMore: false,
        isLeadNew: true,
        isTargetZone: true,
        isTestEmail: false,
      });
      spyOn(api.auth(), "updateProfile");
      spyOn(MarketingService.analytics(), "trackRNTLeadOwnerGoal2");
      spyOn(MarketingService.analytics(), "trackRNTLeadViableGoal4");

      //when
      const flapForm = component();
      flapForm.vm.setAddress({
        geometry: {
          location: {
            lat: jest.fn(() => 1),
            lng: jest.fn(() => 2),
          },
        },
      });

      await flapForm.vm.generateLead();

      //then
      expect(api.auth().updateProfile).not.toHaveBeenCalled();
      expect(api.lead().rentals).toHaveBeenCalledWith({
        countryIsoCode: "ES",
        email: "",
        internationalPhone: "international phone",
        isBot: false,
        latitude: 1,
        longitude: 2,
        originId: 1,
        referer: "href",
        role: "role",
      });
      expect(flapForm.vm.phoneStatus).not.toContain("error");
      expect(flapForm.vm.buttonPressed).toBeTruthy();
      expect(flapForm.vm.error).toBeFalsy();
      expect(
        MarketingService.analytics().trackRNTLeadOwnerGoal2
      ).toHaveBeenCalled();
      expect(
        MarketingService.analytics().trackRNTLeadViableGoal4
      ).toHaveBeenCalled();

      expect(flapForm.emitted().success?.length).toBe(1);
    });

    it("Should show an error if call to api fails", async () => {
      //given
      jest.spyOn(AuthService, "currentUser", "get").mockReturnValue({
        phone: {
          countryCode: 34,
          phone: "phone",
          internationalPhone: "international phone",
        },
      });
      spyOn(api.lead(), "rentals").and.throwError;
      spyOn(api.auth(), "updateProfile");
      spyOn(MarketingService.analytics(), "trackRNTLeadOwnerGoal2");
      spyOn(MarketingService.analytics(), "trackRNTLeadViableGoal4");

      //when
      const flapForm = component();
      flapForm.vm.setAddress({
        geometry: {
          location: {
            lat: jest.fn(() => 1),
            lng: jest.fn(() => 2),
          },
        },
      });

      await flapForm.vm.generateLead();

      //then
      expect(api.lead().rentals).toHaveBeenCalledWith({
        countryIsoCode: "ES",
        email: "",
        internationalPhone: "international phone",
        isBot: false,
        latitude: 1,
        longitude: 2,
        originId: 1,
        referer: "href",
        role: "role",
      });
      expect(flapForm.vm.phoneStatus).not.toContain("error");
      expect(flapForm.vm.buttonPressed).toBeTruthy();
      expect(
        MarketingService.analytics().trackRNTLeadOwnerGoal2
      ).not.toHaveBeenCalled();
      expect(
        MarketingService.analytics().trackRNTLeadViableGoal4
      ).not.toHaveBeenCalled();

      expect(flapForm.emitted().success?.length).toBeFalsy();
      expect(flapForm.vm.error).toBeTruthy();
      expect(flapForm.vm.loading).toBeFalsy();
    });
  });
});
